import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certificate-config',
  templateUrl: './certificate-config.component.html',
  styleUrls: ['./certificate-config.component.css']
})
export class CertificateConfigComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
