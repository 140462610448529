import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-certificate-templates',
  templateUrl: './manage-certificate-templates.component.html',
  styleUrls: ['./manage-certificate-templates.component.css']
})
export class ManageCertificateTemplatesComponent implements OnInit {
  organization: string = "@@Organization Name@@"; 
  address: string = "@@Address or Note@@"; 
  certificate: string = "@@Certificate Name@@";
  text1: string = "@@ Name @@ of @@ Institute @@";
  selected = "";
  text3: string = "@@Prizenane@@ in the @@event@@ of @@festive@@";
  text4: string = "@@Stage@@ @@Event Type@@ held at @@Level of Events@@";
  text5: string = "Conducted from @@date@@ to @@date@@";
  text6: string = "Text goes Here";
  text7: string = "Text goes Here";
  text8: string = "This is to certify";
  text9: string = "Date of Issue";
  text10: string = "Validity Date";
  constructor() { }

  ngOnInit() {
  }

}
