import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
export interface Element {
  Team_Name: string;
  Participants: string;
  Coaches: string;
  Representivies: string;
}
@Component({
  selector: 'app-registration-approve-cancel',
  templateUrl: './registration-approve-cancel.component.html',
  styleUrls: ['./registration-approve-cancel.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RegistrationApproveCancelComponent implements OnInit {
  displayedColumns = ['Action','Team_Name', 'Participants', 'Coaches', 'Representivies'];
  dataSource = new ExampleDataSource();
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  expandedElement: any;
  constructor() { }

  ngOnInit() {
  }

}
const data: Element[] = [
  { Team_Name: 'new1', Participants: '2', Coaches: '1', Representivies: '2' },
  { Team_Name: 'new2', Participants: '3', Coaches: '3', Representivies: '3' },
  { Team_Name: 'new3', Participants: '1', Coaches: '2', Representivies: '4' },
  { Team_Name: 'new4', Participants: '6', Coaches: '4', Representivies: '5' }
];

export class ExampleDataSource extends DataSource<any> {
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Element[]> {
    const rows = [];
    data.forEach(element => rows.push(element, { detailRow: true, element }));
    console.log(rows);
    return of(rows);
  }

  disconnect() { }
}