import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.css']
})
export class EventViewComponent implements OnInit {

  disablename=false;

  constructor() { }

  ngOnInit() {
  }
  verifydigi()
  {
  this.disablename=true;
  }
}
