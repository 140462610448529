import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-conduct-team',
  templateUrl: './manage-conduct-team.component.html',
  styleUrls: ['./manage-conduct-team.component.css']
})
export class ManageConductTeamComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  show= false;
  ClickData()
  {
    this.show=true; 
  }
}
