import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-view',
  templateUrl: './public-view.component.html',
  styleUrls: ['./public-view.component.css']
})
export class PublicViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
