import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addcertificatetemplates',
  templateUrl: './addcertificatetemplates.component.html',
  styleUrls: ['./addcertificatetemplates.component.css']
})
export class AddcertificatetemplatesComponent implements OnInit {

  organization: string = "@@Organization Name@@"; 
  address: string = "@@Address or Note@@"; 
  certificate: string = "@@Certificate Name@@";
  text1: string = "@@ Name @@ of @@ Institute @@";
  selected = "";
  text3: string = "@@Prizenane@@ in the @@event@@ of @@festive@@";
  text4: string = "@@Stage@@ @@Event Type@@ held at @@Level of Events@@";
  text5: string = "Conducted from @@date@@ to @@date@@";
  text6: string = "Text goes Here";
  text7: string = "Text goes Here";
  text8: string = "This is to certify";
  text9: string = "Date of Issue: 20-Jan-2020";
  text10: string = "Validity till 22-Jan-2022";
  uploadlogo:any;
  uploadsign:any;
  uploadsignspace:any;
  uploadqrcode:any;
  uploadlogo1:any;
  uploadsign1:any;
  uploadsignspace1:any;
  uploadqrcode1:any;
  uploadlogo2:any;
  uploadsign2:any;
  uploadsignspace2:any;
  uploadqrcode2:any;
  uploadlogo3:any;
  uploadsign3:any;
  uploadsignspace3:any;
  uploadqrcode3:any;

  fileEvtType: any;
  fileName: any;
  imagePath: any;
  imgURL: any;
  imgURL1:any;
  imgURL2:any;
  DocType1:any;
  binaryString1: any;
  base64textString1: any;
  Eventpicture: any;
  Portrait= false;
  Landscape= true;
  panelOpenState = false;


  constructor() { }

  ngOnInit() {
  }

  changeEventLister(e) {
    let evtFile = e.target.files[0];
    this.fileEvtType = evtFile.type;
    this.fileName = evtFile.name
    let readers = new FileReader();
    readers.readAsBinaryString(evtFile);
    var reader = new FileReader();
    this.imagePath = evtFile;
    reader.readAsDataURL(evtFile);
    reader.onload = (_event) => {
    this.imgURL = reader.result;
}
this.DocType1 = this.fileName.split(".").pop();
}
changeEventLister1(e) {
  let evtFile = e.target.files[0];
  this.fileEvtType = evtFile.type;
  this.fileName = evtFile.name
  let readers = new FileReader();
  readers.readAsBinaryString(evtFile);
  var reader = new FileReader();
  this.imagePath = evtFile;
  reader.readAsDataURL(evtFile);
  reader.onload = (_event) => {
  this.imgURL1 = reader.result;
}
this.DocType1 = this.fileName.split(".").pop();
}
changeEventLister2(e) {
  let evtFile = e.target.files[0];
  this.fileEvtType = evtFile.type;
  this.fileName = evtFile.name
  let readers = new FileReader();
  readers.readAsBinaryString(evtFile);
  var reader = new FileReader();
  this.imagePath = evtFile;
  reader.readAsDataURL(evtFile);
  reader.onload = (_event) => {
  this.imgURL2 = reader.result;
}
this.DocType1 = this.fileName.split(".").pop();
}

onSelectionChange(e){
  let SelectedRadio = e.value;
  console.log(e.value,'radio cchange event');
  if(SelectedRadio == 1){
    this.uploadlogo=true;
    this.uploadsign=false;
    this.uploadsignspace=false;
    this.uploadqrcode=false;
  }
  if(SelectedRadio == 2){
    this.uploadlogo=false;
    this.uploadsign=true;
    this.uploadsignspace=false;
    this.uploadqrcode=false;
  }
  if(SelectedRadio == 3){
    this.uploadlogo=false;
    this.uploadsign=false;
    this.uploadsignspace=true;
    this.uploadqrcode=false;
  }
  if(SelectedRadio == 4){
    this.uploadlogo=false;
    this.uploadsign=false;
    this.uploadsignspace=false;
    this.uploadqrcode=true;
  }
  
}
onSelectionChange1(e){
  let SelectedRadio = e.value;
  console.log(e.value,'radio cchange event');
  if(SelectedRadio == 1){
    this.uploadlogo1=true;
    this.uploadsign1=false;
    this.uploadsignspace1=false;
    this.uploadqrcode1=false;
  }
  if(SelectedRadio == 2){
    this.uploadlogo1=false;
    this.uploadsign1=true;
    this.uploadsignspace1=false;
    this.uploadqrcode1=false;
  }
  if(SelectedRadio == 3){
    this.uploadlogo1=false;
    this.uploadsign1=false;
    this.uploadsignspace1=true;
    this.uploadqrcode1=false;
  }
  if(SelectedRadio == 4){
    this.uploadlogo1=false;
    this.uploadsign1=false;
    this.uploadsignspace1=false;
    this.uploadqrcode1=true;
  }
  
}
onSelectionChange2(e){
  let SelectedRadio = e.value;
  console.log(e.value,'radio cchange event');
  if(SelectedRadio == 1){
    this.uploadlogo2=true;
    this.uploadsign2=false;
    this.uploadsignspace2=false;
    this.uploadqrcode2=false;
  }
  if(SelectedRadio == 2){
    this.uploadlogo2=false;
    this.uploadsign2=true;
    this.uploadsignspace2=false;
    this.uploadqrcode2=false;
  }
  if(SelectedRadio == 3){
    this.uploadlogo2=false;
    this.uploadsign2=false;
    this.uploadsignspace2=true;
    this.uploadqrcode2=false;
  }
  if(SelectedRadio == 4){
    this.uploadlogo2=false;
    this.uploadsign2=false;
    this.uploadsignspace2=false;
    this.uploadqrcode2=true;
  }
  
}
onSelectionChange3(e){
  let SelectedRadio = e.value;
  console.log(e.value,'radio cchange event');
  if(SelectedRadio == 1){
    this.uploadlogo3=true;
    this.uploadsign3=false;
    this.uploadsignspace3=false;
    this.uploadqrcode3=false;
  }
  if(SelectedRadio == 2){
    this.uploadlogo3=false;
    this.uploadsign3=true;
    this.uploadsignspace3=false;
    this.uploadqrcode3=false;
  }
  if(SelectedRadio == 3){
    this.uploadlogo3=false;
    this.uploadsign3=false;
    this.uploadsignspace3=true;
    this.uploadqrcode3=false;
  }
  if(SelectedRadio == 4){
    this.uploadlogo3=false;
    this.uploadsign3=false;
    this.uploadsignspace3=false;
    this.uploadqrcode3=true;
  }
  
}
onSelectionChange4(e){
  console.log(e.target.value,' cchange event');
  let PaymentMode = e.target.value;
  if(PaymentMode == 2){
    this.Portrait=true;
    this.Landscape=false;
  }
  if(PaymentMode == 1){
    this.Portrait=false;
    this.Landscape=true;
  }
}

}
