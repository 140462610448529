/*import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-manager-dashboard',
  templateUrl: './event-manager-dashboard.component.html',
  styleUrls: ['./event-manager-dashboard.component.css']
})
export class EventManagerDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}*/
/*import { Component, OnInit } from '@angular/core';
import * as CanvasJS from '../../../assets/js/jquery.canvasjs.min';
//var CanvasJS = require('./canvasjs.min');
 
@Component({
  selector: 'app-event-manager-dashboard',
  templateUrl: './event-manager-dashboard.component.html',
  styleUrls: ['./event-manager-dashboard.component.css']
})
 
export class EventManagerDashboardComponent implements OnInit {
	ngOnInit() {
		let chart = new CanvasJS.Chart("chartContainer", {
		animationEnabled: true,
		exportEnabled: true,
		title: {
			text: "Participation Counts"
		},
		data: [{
			type: "column",
			dataPoints: [
				{ y: 71, label: "Festive Event 1" },
				{ y: 55, label: "Festive Event 2" },
				{ y: 50, label: "Festive Event 3" },
				{ y: 65, label: "Festive Event 4" },
				{ y: 55, label: "Festive Event 5" },
				{ y: 45, label: "Festive Event 6" },
				{ y: 75, label: "Festive Event 7" },
				{ y: 56, label: "Festive Event 8" }
    	]
		}]
	});
		
	chart.render();
    }
}*/
import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-event-manager-dashboard',
  templateUrl: './event-manager-dashboard.component.html',
  styleUrls: ['./event-manager-dashboard.component.css']
})
export class EventManagerDashboardComponent implements OnInit {
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['Festive Event 1', 'Festive Event 2', 'Festive Event 3', 'Festive Event 4', 'Festive Event 5', 'Festive Event 6', 'Festive Event 7'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Individuals' },
    { data: [28, 48, 40, 25, 86, 27, 90], label: 'Teams' }
  ];

  constructor() { }

  ngOnInit() {
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public randomize(): void {
    // Only Change 3 values
    const data = [
      Math.round(Math.random() * 100),
      59,
      80,
      (Math.random() * 100),
      56,
      (Math.random() * 100),
      40];
    this.barChartData[0].data = data;
  }
}