import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './materialModule';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from "ng2-file-upload";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppComponent } from './app.component';
import { appRoutes } from './routerConfig'; 

import { HeaderComponent } from './components/Layout/header/header.component';
import { SideBarComponent } from './components/Layout/side-bar/side-bar.component';
import { FooterComponent } from './components/Layout/footer/footer.component';



import { FestEventComponent } from './components/fest-event/fest-event.component';

import { NgxEditorModule } from 'ngx-editor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ManageEventComponent } from './components/manage-event/manage-event.component';
import { AddEventComponent } from './components/add-event/add-event.component';
import { AddFestiveEventComponent } from './components/add-festive-event/add-festive-event.component';
import { PublicViewComponent } from './components/public-view/public-view.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { StudentLoginComponent } from './components/student-login/student-login.component';
import { OthersLoginComponent } from './components/others-login/others-login.component';
import { LoginLayoutComponent } from './components/Layout/login-layout/login-layout.component';
import { ViewPublicPageComponent } from './components/view-public-page/view-public-page.component';
import { EventListComponent } from './components/event-list/event-list.component';
import { EventViewComponent } from './components/event-view/event-view.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { ViewEventTeamComponent } from './components/view-event-team/view-event-team.component';
import { AddTeamComponent } from './components/add-team/add-team.component';
import {ManageEventStatusComponent } from './components/manage-event-status/manage-event-status.component';
import { ManageParticipationComponent } from './components/manage-participation/manage-participation.component';
import { ManageParticipantRegisterComponent } from './components/manage-participant-register/manage-participant-register.component';
import { RegisteredParticipantComponent } from './components/registered-participant/registered-participant.component';
import { RegistrationApproveCancelComponent } from './components/registration-approve-cancel/registration-approve-cancel.component';
import { ProfileComponent } from './components/profile/profile.component';
import { FestiveManagerDashboardComponent } from './components/festive-manager-dashboard/festive-manager-dashboard.component';
import { EventManagerDashboardComponent } from './components/event-manager-dashboard/event-manager-dashboard.component';
import { ParticipantsDashboardComponent } from './components/participants-dashboard/participants-dashboard.component';
import { TeamEventParticipantsComponent } from './components/team-event-participants/team-event-participants.component';
import { EditFestiveEventComponent } from './components/edit-festive-event/edit-festive-event.component';
import { EditEventComponent } from './components/edit-event/edit-event.component';
import { ManageConductIndividualComponent } from './components/manage-conduct-individual/manage-conduct-individual.component';
import { ManageEventConductIndividualComponent } from './components/manage-event-conduct-individual/manage-event-conduct-individual.component';
import { ManageEventConductTeamComponent } from './components/manage-event-conduct-team/manage-event-conduct-team.component';
import { ManageConductTeamComponent } from './components/manage-conduct-team/manage-conduct-team.component';
import { ViewPrizeComponent } from './components/view-prize/view-prize.component';
import { ManageFestiveEventsComponent } from './components/manage-festive-events/manage-festive-events.component';
import { SelectFestiveEventsComponent } from './components/select-festive-events/select-festive-events.component';
import { ManageParticipantTeamComponent } from './components/manage-participant-team/manage-participant-team.component';
import { ChartsModule } from 'ng2-charts';

import { ImageCropperModule } from 'ngx-image-cropper';
import { MakepaymentComponent } from './components/makepayment/makepayment.component';
import { PaymentRegistrationComponent } from './components/payment-registration/payment-registration.component';
import { IndividualEventParticipantComponent } from './components/individual-event-participant/individual-event-participant.component';
import { TeamEventParticipantComponent } from './components/team-event-participant/team-event-participant.component';
import { ManageTeamEventComponent } from './components/manage-team-event/manage-team-event.component';
import { ListFestiveEventsComponent } from './components/list-festive-events/list-festive-events.component';
import { CertificateConfigComponent } from './components/certificate-config/certificate-config.component';
import { ViewOtherCalendarHappeningsComponent} from './components/view-other-calendar-happenings/view-other-calendar-happenings.component';

/*import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';*/
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ManageCertificateTemplatesComponent } from './components/manage-certificate-templates/manage-certificate-templates.component';
import { AddcertificatetemplatesComponent } from './components/addcertificatetemplates/addcertificatetemplates.component';
import { EditcertificatetemplatesComponent } from './components/editcertificatetemplates/editcertificatetemplates.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideBarComponent,
    FooterComponent,
    FestEventComponent,
    ManageEventComponent,
    AddEventComponent,
    AddFestiveEventComponent,
    PublicViewComponent,
    RegistrationComponent,
    StudentLoginComponent,
    OthersLoginComponent,
    LoginLayoutComponent,
    ViewPublicPageComponent,
    EventListComponent,
    EventViewComponent,
    ConfigurationComponent,
    ViewEventTeamComponent,
    AddTeamComponent,
    ManageParticipationComponent,
    ManageParticipantRegisterComponent,
    RegisteredParticipantComponent,
    RegistrationApproveCancelComponent,
    ProfileComponent,
    ManageEventStatusComponent,
    FestiveManagerDashboardComponent,
    EventManagerDashboardComponent,
    ParticipantsDashboardComponent,
    TeamEventParticipantsComponent,
    EditFestiveEventComponent,
    EditEventComponent,
    ManageConductIndividualComponent,
    ManageEventConductIndividualComponent,
    ManageEventConductTeamComponent,
    ManageConductTeamComponent,
    ViewPrizeComponent,
    ManageFestiveEventsComponent,
    SelectFestiveEventsComponent,
    ManageParticipantTeamComponent,
    MakepaymentComponent,
    PaymentRegistrationComponent,
    IndividualEventParticipantComponent,
    TeamEventParticipantComponent,
    ManageTeamEventComponent,
    ListFestiveEventsComponent,
    CertificateConfigComponent,
    ViewOtherCalendarHappeningsComponent,
    ManageCertificateTemplatesComponent,
    AddcertificatetemplatesComponent,
    EditcertificatetemplatesComponent
],
  imports: [
    BrowserModule, 
    TooltipModule ,
    MaterialModule,
    TooltipModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    NgbModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    FileUploadModule,
    HttpClientModule, ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    UiSwitchModule,
    DataTablesModule,
    NgSelectModule,
    NgxEditorModule,
    ImageCropperModule,
    ChartsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
   
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
