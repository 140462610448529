import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-event-conduct-team',
  templateUrl: './manage-event-conduct-team.component.html',
  styleUrls: ['./manage-event-conduct-team.component.css']
})
export class ManageEventConductTeamComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
