import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registered-participant',
  templateUrl: './registered-participant.component.html',
  styleUrls: ['./registered-participant.component.css']
})
export class RegisteredParticipantComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
