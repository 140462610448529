import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-event-conduct-individual',
  templateUrl: './manage-event-conduct-individual.component.html',
  styleUrls: ['./manage-event-conduct-individual.component.css']
})
export class ManageEventConductIndividualComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
