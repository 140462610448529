import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-event-status',
  templateUrl: './manage-event-status.component.html',
  styleUrls: ['./manage-event-status.component.css']
})
export class ManageEventStatusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
