/*import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-festive-event',
  templateUrl: './edit-festive-event.component.html',
  styleUrls: ['./edit-festive-event.component.css']
})
export class EditFestiveEventComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}*/
import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { FileUploader } from 'ng2-file-upload';
import { ImageCroppedEvent } from 'ngx-image-cropper';

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

@Component({
  selector: 'app-edit-festive-event',
  templateUrl: './edit-festive-event.component.html',
  styleUrls: ['./edit-festive-event.component.css']
})
export class EditFestiveEventComponent implements OnInit {
  imageChangedEvent: any = '';
    croppedImage: any = '';
  myControl = new FormControl();
  options: string[] = ['Bilal', 'Priya', 'Iniyan', 'GokulaKrishnan'];
  filteredOptions: Observable<string[]>;
  public uploader:FileUploader = new FileUploader({url: URL});
  public hasBaseDropZoneOver:boolean = false;
  public hasAnotherDropZoneOver:boolean = false;
 
  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }
 
  public fileOverAnother(e:any):void {
    this.hasAnotherDropZoneOver = e;
  }
  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
}
imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
}
imageLoaded() {
    // show cropper
}
cropperReady() {
    // cropper ready
}
loadImageFailed() {
    // show message
}

}
