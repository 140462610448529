import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-conduct-individual',
  templateUrl: './manage-conduct-individual.component.html',
  styleUrls: ['./manage-conduct-individual.component.css']
})
export class ManageConductIndividualComponent implements OnInit {
 
  constructor() { }

  ngOnInit() {
  }
 show= false;
 ClickData()
 {
   this.show=true; 
 }


}
