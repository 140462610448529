import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-others-login',
  templateUrl: './others-login.component.html',
  styleUrls: ['./others-login.component.css'],
})
export class OthersLoginComponent implements OnInit {
  show = false;
  hide =true;
  namebox=false;
  digilockerusage=false;
  constructor(private router: Router) { }

  ngOnInit() {
  }
  yes() {
    this.router.navigate(['/student-login']);
  }
  no() {

    this.show = true;
    this.hide = false;
  }
  signin()
  {
   this.namebox=true;
   this.digilockerusage=true;
  }
}
