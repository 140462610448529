import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-makepayment',
  templateUrl: './makepayment.component.html',
  styleUrls: ['./makepayment.component.css']
})
export class MakepaymentComponent implements OnInit {

  PayByDD=false;
  PayByCheque=false;
  PayByCash=false;

  constructor() { }

  ngOnInit() {
  }
  onSelectionChange1(e){
    console.log(e.target.value,' cchange event');
    let PaymentMode = e.target.value;
    if(PaymentMode == 1){
      this.PayByCheque=false;
      this.PayByCash=true;
    }
    if(PaymentMode == 2){
      this.PayByCheque=true;
      this.PayByCash=false;
    }
    if(PaymentMode == 3){
      this.PayByCheque=true;
      this.PayByCash=false;
    }
    if(PaymentMode == 4){
      this.PayByCheque=true;
      this.PayByCash=false;
    }
  }
  onSelectionChange(e){
    let SelectedRadio = e.value;
    console.log(e.value,'radio cchange event');
    if(SelectedRadio == 1){
      this.PayByDD=false;
      this.PayByCheque=false;
      this.PayByCash=false;
    }
    if(SelectedRadio == 2){
      this.PayByDD=true;
      this.PayByCash=true;
    }
    
  }
}
