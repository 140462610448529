// routerConfig.ts
import { Routes } from '@angular/router';
import { FestEventComponent } from './components/fest-event/fest-event.component';
import { ManageEventComponent } from './components/manage-event/manage-event.component';
import { AddEventComponent } from './components/add-event/add-event.component';
import { AddFestiveEventComponent } from './components/add-festive-event/add-festive-event.component';
import { PublicViewComponent } from './components/public-view/public-view.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { StudentLoginComponent } from './components/student-login/student-login.component';
import { OthersLoginComponent } from './components/others-login/others-login.component';
import { ViewPublicPageComponent } from './components/view-public-page/view-public-page.component';
import { EventListComponent } from './components/event-list/event-list.component';
import { EventViewComponent } from './components/event-view/event-view.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { ViewEventTeamComponent } from './components/view-event-team/view-event-team.component';
import { AddTeamComponent } from './components/add-team/add-team.component';
import { ManageParticipationComponent } from './components/manage-participation/manage-participation.component';
import { ManageParticipantRegisterComponent } from './components/manage-participant-register/manage-participant-register.component';
import { RegisteredParticipantComponent } from './components/registered-participant/registered-participant.component';
import { RegistrationApproveCancelComponent } from './components/registration-approve-cancel/registration-approve-cancel.component';
import { ProfileComponent } from './components/profile/profile.component';
import { FestiveManagerDashboardComponent } from './components/festive-manager-dashboard/festive-manager-dashboard.component';
import { EventManagerDashboardComponent } from './components/event-manager-dashboard/event-manager-dashboard.component';
import { ParticipantsDashboardComponent } from './components/participants-dashboard/participants-dashboard.component';
import { TeamEventParticipantsComponent } from './components/team-event-participants/team-event-participants.component';
import { ManageEventStatusComponent } from './components/manage-event-status/manage-event-status.component';
import { EditFestiveEventComponent } from './components/edit-festive-event/edit-festive-event.component';
import { EditEventComponent } from './components/edit-event/edit-event.component';
import { ManageConductIndividualComponent } from './components/manage-conduct-individual/manage-conduct-individual.component';
import { ManageEventConductIndividualComponent } from './components/manage-event-conduct-individual/manage-event-conduct-individual.component';
import { ManageEventConductTeamComponent } from './components/manage-event-conduct-team/manage-event-conduct-team.component';
import { ManageConductTeamComponent } from './components/manage-conduct-team/manage-conduct-team.component';
import { ViewPrizeComponent } from './components/view-prize/view-prize.component';
import {ManageFestiveEventsComponent} from './components/manage-festive-events/manage-festive-events.component';
import {SelectFestiveEventsComponent} from './components/select-festive-events/select-festive-events.component';
import {ManageParticipantTeamComponent} from './components/manage-participant-team/manage-participant-team.component';
import {MakepaymentComponent} from './components/makepayment/makepayment.component';
import { PaymentRegistrationComponent } from './components/payment-registration/payment-registration.component';
import { IndividualEventParticipantComponent } from './components/individual-event-participant/individual-event-participant.component';
import { TeamEventParticipantComponent } from './components/team-event-participant/team-event-participant.component';
import { ManageTeamEventComponent } from './components/manage-team-event/manage-team-event.component';
import { ListFestiveEventsComponent } from './components/list-festive-events/list-festive-events.component';
import { CertificateConfigComponent } from './components/certificate-config/certificate-config.component';
import { ViewOtherCalendarHappeningsComponent} from './components/view-other-calendar-happenings/view-other-calendar-happenings.component';
import { ManageCertificateTemplatesComponent} from './components/manage-certificate-templates/manage-certificate-templates.component';
import { AddcertificatetemplatesComponent} from './components/addcertificatetemplates/addcertificatetemplates.component';
import { EditcertificatetemplatesComponent} from './components/editcertificatetemplates/editcertificatetemplates.component';

export const appRoutes: Routes = [
  {
      path: '',
      component: FestEventComponent
  },
  {
    path: 'manage-event',
    component: ManageEventComponent
  },
  {
    path:'add-event',
    component: AddEventComponent
  },
  {
    path: 'add-festive-event',
    component: AddFestiveEventComponent
  },
  {
    path:'public-view',
    component: PublicViewComponent
  },
  {
    path: 'registration',
    component: RegistrationComponent
  },
  {
    path: 'student-login',
    component: StudentLoginComponent
  },
  {
    path: 'register',
    component: OthersLoginComponent
  },
  {
    path: 'view-festive-details',
    component : ViewPublicPageComponent
  },
  {
    path: 'event-list',
    component: EventListComponent
  },
  {
    path: 'event-view',
    component: EventViewComponent
  },
  {
    path: 'configuration',
    component: ConfigurationComponent
  },
  {
    path: 'view-event-team',
    component: ViewEventTeamComponent
  },
  {
    path: 'add-team',
    component: AddTeamComponent
  },
  {
    path: 'manage-participation',
    component: ManageParticipationComponent
  }, {
    path: 'registered-participant',
    component:ManageParticipantRegisterComponent
  },
  {
    path: 'registered-view-details',
    component: RegisteredParticipantComponent
  },
  {
    path: 'individual-register-cancel',
    component: RegistrationApproveCancelComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'festive-dashboard',
    component: FestiveManagerDashboardComponent
  },
  {
    path: 'event-dashboard',
    component: EventManagerDashboardComponent
  },
  {
    path: 'participants-dashboard',
    component: ParticipantsDashboardComponent
  },
  {
    path: 'team-event-participant',
    component: TeamEventParticipantsComponent
  },
  {
    path: 'manage-event-status',
    component: ManageEventStatusComponent
  },
  {
    path: 'edit-festive-event',
    component: EditFestiveEventComponent
  },
  {
    path: 'edit-event',
    component: EditEventComponent
  },
  {
    path: 'Conduct-individual',
    component: ManageConductIndividualComponent
  },
  {
    path: 'manage-conduct-individual',
    component: ManageEventConductIndividualComponent
  },
  {
    path: 'manage-conduct-team',
    component: ManageEventConductTeamComponent
  },
  {
    path: 'Conduct-team',
    component: ManageConductTeamComponent
  },
  {
    path: 'view-prize',
    component: ViewPrizeComponent
  },
  {
    path: 'manage-festive-events',
    component: ManageFestiveEventsComponent
  },
  {
    path: 'select-festive-events',
    component: SelectFestiveEventsComponent
  },
  {
    path: 'view-public-page',
    component: ViewPublicPageComponent
  },
  {
    path: 'manage-participant-team',
    component: ManageParticipantTeamComponent
  },
  {
    path: 'makepayment',
    component: MakepaymentComponent
  },
  {
    path: 'payment_Registration',
    component:PaymentRegistrationComponent
  },
  {
    path: 'individual_event_participant',
    component: IndividualEventParticipantComponent
  },
  {
    path: 'Team_event_participant',
    component: TeamEventParticipantComponent
  },
  {
    path: 'manage-team-event',
    component: ManageTeamEventComponent
  },
  {
    path: 'list-festive-event',
    component: ListFestiveEventsComponent
  },
  {
    path: 'Certificate-Config',
    component: CertificateConfigComponent
  },
  {
    path: 'view-other-calendar-happenings',
    component: ViewOtherCalendarHappeningsComponent
  },
  {
    path: 'manage-certificate-template',
    component: ManageCertificateTemplatesComponent
  },
  {
    path: 'add-certificate-template',
    component: AddcertificatetemplatesComponent
  },
  {
    path: 'edit-certificate-template',
    component: EditcertificatetemplatesComponent
  }
];
