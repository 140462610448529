// import { Component, OnInit,ViewChild } from '@angular/core';
// import {MatSidenavContainer} from "@angular/material";
// @Component({
//   selector: 'app-side-bar',
//   templateUrl: './side-bar.component.html',
//   styleUrls: ['./side-bar.component.css']
// })

// export class SideBarComponent implements OnInit {
//   showMenu = false;
//   RevshowMenu=false;
//   DevshowMenu= false;
//   manageCamshowMenu = false;
//   manageVenushowMenu=false;

//   constructor() { }
//   @ViewChild('navcontainer') navcontainer: MatSidenavContainer;
//   ngOnInit() {
    
//   }
//   toggleMenu() {
//     this.showMenu = !this.showMenu;
 
//  }
//  ReviewtoggleMenu() {
//     this.RevshowMenu = !this.RevshowMenu;
 
//  }
//  DeviationtoggleMenu() {
//     this.DevshowMenu = !this.DevshowMenu;
 
//  }
//  ManageCamtoggleMenu() {
//   this.manageCamshowMenu = !this.manageCamshowMenu;

// }
// ManageVenuetoggleMenu() {
//   this.manageVenushowMenu = !this.manageVenushowMenu;

// }
// }
import { Component, OnInit } from '@angular/core';
import {MatSidenavContainer} from "@angular/material";
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {

  subMenu=false;
  UnicampusSubMenu3 = false;
  UnicampusSubMenu2 = false;
  ReviewsubMenu=false;
  DeviationsubMenu=false;
  ResSubMenuClick=false;
  ResSubMenu=false;
  themesubMenu=false;
  themeIconsubMenu=false;
  MainsubMenu=false;
  UnicampusSubMenu=false;
  UniVenueSubMenu=false;
  collegeEventSubMenu=false;
  studentEventSubMenu=false;
  univEventSubMenu=false;
  UnicampusSubMenu1 =false;
  constructor() { }

  ngOnInit() {
    this.menuUniCampusesClick();

  }
  menuClick(){

  this.subMenu=!this.subMenu;
  this.ReviewsubMenu=false;
  this.DeviationsubMenu=false;
  this.ResSubMenuClick=false;
  this.ResSubMenu=false;
  this.themesubMenu=false;
  this.themeIconsubMenu=false;
  this.MainsubMenu=false;
  this.UnicampusSubMenu=false;
  this.UniVenueSubMenu=false;
  
  this.collegeEventSubMenu=false;
  this.studentEventSubMenu=false;
  this.univEventSubMenu=false;
  }
  menuReviewerClick(){
    this.ReviewsubMenu=!this.ReviewsubMenu;
    this.subMenu=false;
  this.DeviationsubMenu=false;
  this.ResSubMenuClick=false;
  this.ResSubMenu=false;
  this.themesubMenu=false;
  this.themeIconsubMenu=false;
  this.MainsubMenu=false;
  this.UnicampusSubMenu=false;
  this.UniVenueSubMenu=false;
  this.collegeEventSubMenu=false;
  this.studentEventSubMenu=false;
  this.univEventSubMenu=false;

  }
  menuDeviationClick(){
    this.DeviationsubMenu=!this.DeviationsubMenu;
    this.subMenu=false;
  this.ReviewsubMenu=false;
  this.ResSubMenuClick=false;
  this.ResSubMenu=false;
  this.themesubMenu=false;
  this.themeIconsubMenu=false;
  this.MainsubMenu=false;
  this.UnicampusSubMenu=false;
  this.UniVenueSubMenu=false;
  this.collegeEventSubMenu=false;
  this.studentEventSubMenu=false;
  this.univEventSubMenu=false;

  }
  resAside(){

    this.ResSubMenuClick=!this.ResSubMenuClick;
    this.ResSubMenu=!this.ResSubMenu;
    
  }
  menuThemeClick(){

    this.themesubMenu=!this.themesubMenu;
    this.DeviationsubMenu=false;
    this.subMenu=false;
    this.ReviewsubMenu=false;
    this.ResSubMenuClick=false;
    this.ResSubMenu=false;
    this.MainsubMenu=false;
    this.UnicampusSubMenu=false;
    this.UniVenueSubMenu=false;
    this.collegeEventSubMenu=false;
    this.studentEventSubMenu=false;
    this.univEventSubMenu=false;
  }
  menuThemeIconClick(){

    this.themeIconsubMenu=!this.themeIconsubMenu;
    this.themesubMenu=true;
    this.DeviationsubMenu=false;
    this.subMenu=false;
    this.ReviewsubMenu=false;
    this.ResSubMenuClick=false;
    this.ResSubMenu=false;
    this.MainsubMenu=false;
    this.UnicampusSubMenu=false;
    this.UniVenueSubMenu=false;
    this.collegeEventSubMenu=false;
    this.studentEventSubMenu=false;
    this.univEventSubMenu=false;
  }
  MainmenuClick(){
this.MainsubMenu=!this.MainsubMenu;

  }
  menuUniCampusesClick(){
    this.UnicampusSubMenu=!this.UnicampusSubMenu;
    this.DeviationsubMenu=false;
    this.subMenu=false;
    this.ReviewsubMenu=false;
    this.ResSubMenuClick=false;
    this.ResSubMenu=false;
    this.MainsubMenu=false;
    this.UniVenueSubMenu=false;
    this.collegeEventSubMenu=false;
    this.studentEventSubMenu=false;
    this.univEventSubMenu=false;
      }
      menuUniCampusesClickNew() {
        this.UnicampusSubMenu1=!this.UnicampusSubMenu1;
    this.DeviationsubMenu=false;
    this.subMenu=false;
    this.ReviewsubMenu=false;
    this.ResSubMenuClick=false;
    this.ResSubMenu=false;
    this.MainsubMenu=false;
    this.UniVenueSubMenu=false;
    this.collegeEventSubMenu=false;
    this.studentEventSubMenu=false;
    this.univEventSubMenu=false;
      }
      menuUniCampusesClickNew1() {
        this.UnicampusSubMenu2=!this.UnicampusSubMenu2;
        this.DeviationsubMenu=false;
        this.subMenu=false;
        this.ReviewsubMenu=false;
        this.ResSubMenuClick=false;
        this.ResSubMenu=false;
        this.MainsubMenu=false;
        this.UniVenueSubMenu=false;
        this.collegeEventSubMenu=false;
        this.studentEventSubMenu=false;
        this.univEventSubMenu=false;
      }
      menuUniCampusesClickNew2() {
        this.UnicampusSubMenu3=!this.UnicampusSubMenu3;
        this.DeviationsubMenu=false;
        this.subMenu=false;
        this.ReviewsubMenu=false;
        this.ResSubMenuClick=false;
        this.ResSubMenu=false;
        this.MainsubMenu=false;
        this.UniVenueSubMenu=false;
        this.collegeEventSubMenu=false;
        this.studentEventSubMenu=false;
        this.univEventSubMenu=false;
      }
      menuVenueClick(){
        this.UniVenueSubMenu=!this.UniVenueSubMenu;
        this.DeviationsubMenu=false;
        this.subMenu=false;
        this.ReviewsubMenu=false;
        this.ResSubMenuClick=false;
        this.ResSubMenu=false;
        this.MainsubMenu=false;
        this.UnicampusSubMenu=false;
        this.collegeEventSubMenu=false;
        this.studentEventSubMenu=false;
        this.univEventSubMenu=false;
          }



          menuEventStudentClick(){
            this.studentEventSubMenu=!this.studentEventSubMenu;
            this.collegeEventSubMenu=false;
            this.univEventSubMenu=false;
            this.UniVenueSubMenu=false;
            this.DeviationsubMenu=false;
            this.subMenu=false;
            this.ReviewsubMenu=false;
            this.ResSubMenuClick=false;
            this.ResSubMenu=false;
            this.MainsubMenu=false;
            this.UnicampusSubMenu=false;
          }
          
          menuEventCollegeClick() {
            this.collegeEventSubMenu = !this.collegeEventSubMenu;
            this.studentEventSubMenu = false;
            this.univEventSubMenu = false;
            this.UniVenueSubMenu=false;
            this.DeviationsubMenu=false;
            this.subMenu=false;
            this.ReviewsubMenu=false;
            this.ResSubMenuClick=false;
            this.ResSubMenu=false;
            this.MainsubMenu=false;
            this.UnicampusSubMenu=false;
          }
          
          menuEventUnivClick(){
            this.univEventSubMenu=!this.univEventSubMenu;
            this.studentEventSubMenu=false;
            this.collegeEventSubMenu=false;
            this.UniVenueSubMenu=false;
            this.DeviationsubMenu=false;
            this.subMenu=false;
            this.ReviewsubMenu=false;
            this.ResSubMenuClick=false;
            this.ResSubMenu=false;
            this.MainsubMenu=false;
            this.UnicampusSubMenu=false;
          }
}

